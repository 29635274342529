import {
  SET_VALIDATED_ADDRESS,
  SET_VALIDATE_FLAG,
  SET_VALIDATE_ADDRESS_ERROR,
  SET_VALIDATE_ADDRESS_SUCCESS,
  SET_VALIDATE_ADDRESS_ERROR_DESCRIPTION,
  SET_VALIDATE_ADDRESS_ERROR_RESPONSE,

} from '@types/mutations';
import { VALIDATE_ADDRESS } from '@types/actions';
import { SoftSignInValidation } from '@utils/SoftSignInValidation.js';
import { httpUpdateAPIHeaders } from '@utils/APIUtil';

const $http = require('@vue-util/http');

const VALIDATE_ADDRESS_ENDPOINT = '/regsvc/registry/v1/validateAddress/details';

export default {
  namespaced: true,
  state() {
    return {
      validateAddress: {},
      validatedFlag: false,
      validateAddressError: false,
      validateAddressErrorDescription: 'API Error',
    };
  },
  getters: {},
  mutations: {
    [SET_VALIDATE_FLAG](state, data) {
      state.validatedFlag = data;
    },
    [SET_VALIDATED_ADDRESS](state, payload) {
      state.validateAddress = payload;
    },
    [SET_VALIDATE_ADDRESS_ERROR](state, data) {
      state.validateAddressError = data;
    },
    [SET_VALIDATE_ADDRESS_SUCCESS](state, data) {
      state.validateAddressSuccess = data;
    },
    [SET_VALIDATE_ADDRESS_ERROR_DESCRIPTION](state, data) {
      state.validateAddressErrorDescription = data;
    },
    [SET_VALIDATE_ADDRESS_ERROR_RESPONSE](state, error) {
      state.validateAddressApiErrorResponse = error;
    },
  },
  actions: {
    async [VALIDATE_ADDRESS]({ commit, rootState }, payload) {
      try {
        const response = await $http.post(
              `${VALIDATE_ADDRESS_ENDPOINT}`,
              payload,
              {
                headers: httpUpdateAPIHeaders(rootState, payload, {}, true),
              },
        );

        commit(SET_VALIDATE_FLAG, !!response?.data?.address?.addressDOs?.[0]?.validatedFlag);

        const isError = response?.data?.errors || response?.data?.error;
        if (isError) {
          commit(SET_VALIDATE_ADDRESS_ERROR, true);
          commit(SET_VALIDATE_ADDRESS_ERROR_DESCRIPTION, response?.data?.errors?.[0]?.description);
        } else {
          commit(SET_VALIDATED_ADDRESS, response.data?.address);
          commit(SET_VALIDATE_ADDRESS_SUCCESS, true);
          commit(SET_VALIDATE_ADDRESS_ERROR_DESCRIPTION, '');
        }
      } catch (error) {
        SoftSignInValidation();
        commit(SET_VALIDATE_ADDRESS_ERROR, true);
        commit(SET_VALIDATE_ADDRESS_ERROR_DESCRIPTION, error?.response?.data?.errors?.[0]?.description);
      }
    },

  },
};
