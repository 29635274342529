const FETCH_QUICK_VIEW_PRODUCT_DETAILS = 'getQuickViewProductDetails';
const GET_REGISTRY_HOME_TAB = 'getRegistryHomeTab';
const GET_SEARCH_REGISTRY_RESULTS = 'getSearchRegistryResults';
const CREATE_REGISTRY = 'createRegistry';
const GET_REGISTRY_INFO = 'getRegistryInfo';
const GET_REGISTRY_SETTINGS_DETAILS = 'getRegistrySettingsDetails';
const UPDATE_REGISTRY_SETTINGS_PRIVACY_DETAIL = 'updateRegistrySettingsPrivacyDetail';
const GET_CUSTOMER_INFO = 'getCustomerInfo';
const GET_VISIBILITY_STATUS = 'visibilityStatus';
const GET_ADD_TO_BAG_RESPONSE = 'getAddToBagResponse';
const GET_REGISTRANT_GIFT_TRACKER_RESULTS = 'getRegistrantGiftTrackerResults';
const UPDATE_REGISTRANT_GIFT_TRACKER_STATUS = 'updateRegistrantGiftTrackerStatus';
const GET_REGISTRANT_MY_TAB_INFO = 'getRegistryMyTabInfo';
const UPDATE_PREFERENCE = 'updatePreference';
const UPDATE_REGISTRANT_DETAILS = 'updateRegistrantDetails';
const GET_REGISTRANT_GIFT_CALCULATOR = 'getRegistrantGiftCalculator';
const REMOVE_ITEMS_BY_ID = 'removeItemsByID';
const VALIDATE_ADDRESS = 'validateAddress';
const UPDATE_REGISTRY_EVENT_DETAIL = 'updateRegistryEventDetails';
const UPDATE_NUMBER_OF_GUESTS = 'updateNumberOfGuests';
const UPDATE_REGISTRANT_ADDRESS = 'updateRegistrantAddress';
const GET_LOCATION_SERVICE_INFO = 'getStoreInfo';
const LINK_REGISTRY = 'linkRegistry';
const SET_LINK_SUCCESS_STATE = 'setLinkSuccessState';
const ADD_PURCHASE = 'addPurchase';
const GET_CANVAS = 'getCanvas';
const REMOVE_SUCCESS_ERROR_NOTIFICATION = 'removeSuccessErrorNotification';
const UPDATE_HEADER_BANNER_VALUES = 'updateHeaderBannerValues';
const DELETE_FUTURE_SHIP_ADDRESS = 'deleteFutureShipAddress';
const UPDATE_GIFT_CARD_OPTIONS = 'updateGiftCardOptions';
const SHOW_GIFT_CARD_OPTIONS_SLIDEOUT = 'showGiftCardOptionsSlideout';
const GET_KILLSWITCH_CONFIG = 'getKillSwitchConfig';
const GET_USER_REGISTRIES = 'getUserRegistries';
const GET_BAR_CODE_IMAGE = 'getBarCodeImage';
const UPDATE_DEFAULT_REGISTRY = 'updateDefaultRegistry';

export {
  GET_REGISTRY_INFO,
  VALIDATE_ADDRESS,
  GET_REGISTRY_HOME_TAB,
  GET_SEARCH_REGISTRY_RESULTS,
  CREATE_REGISTRY,
  FETCH_QUICK_VIEW_PRODUCT_DETAILS,
  GET_REGISTRY_SETTINGS_DETAILS,
  UPDATE_REGISTRY_SETTINGS_PRIVACY_DETAIL,
  GET_CUSTOMER_INFO,
  GET_VISIBILITY_STATUS,
  GET_ADD_TO_BAG_RESPONSE,
  GET_REGISTRANT_GIFT_TRACKER_RESULTS,
  UPDATE_REGISTRANT_GIFT_TRACKER_STATUS,
  GET_REGISTRANT_MY_TAB_INFO,
  UPDATE_PREFERENCE,
  REMOVE_ITEMS_BY_ID,
  UPDATE_REGISTRANT_DETAILS,
  UPDATE_REGISTRY_EVENT_DETAIL,
  GET_REGISTRANT_GIFT_CALCULATOR,
  UPDATE_NUMBER_OF_GUESTS,
  UPDATE_REGISTRANT_ADDRESS,
  GET_LOCATION_SERVICE_INFO,
  LINK_REGISTRY,
  SET_LINK_SUCCESS_STATE,
  ADD_PURCHASE,
  GET_CANVAS,
  REMOVE_SUCCESS_ERROR_NOTIFICATION,
  UPDATE_HEADER_BANNER_VALUES,
  DELETE_FUTURE_SHIP_ADDRESS,
  UPDATE_GIFT_CARD_OPTIONS,
  SHOW_GIFT_CARD_OPTIONS_SLIDEOUT,
  GET_KILLSWITCH_CONFIG,
  GET_USER_REGISTRIES,
  GET_BAR_CODE_IMAGE,
  UPDATE_DEFAULT_REGISTRY,
};
